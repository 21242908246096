<template>
  <b-row>
    <b-col cols="5">
      <label class="nbr-title">
        {{ $t('views.marketing.report.resume-content.config') }}
      </label>
      <config-index
          :email-campaign="emailCampaign"
      />
    </b-col>
    <div class="split-layout__divider">
      <div class="split-layout__rule"></div>
    </div>
    <b-col class="col-divider">
      <b-row v-if="isPushCampaign && emailCampaign.title !== null" class="mb-2">
        <b-col cols="12">
          <label class="nbr-title">
            {{ $t('views.marketing.report.resume-content.title') }}
          </label>
          <d-text-field
            :value="emailCampaign.title"
            :disabled="true"
            size="sm"
            class-name="background-light-blue-inputs"
            readonly
          />
        </b-col>
      </b-row>
      <label class="nbr-title">
        {{ $t('views.marketing.report.resume-content' + pushContent) }}
      </label>
      <email-editor
          v-if="isEmailCampaign"
          :default-content="emailCampaign.content"
          :disabled="true"
      />
      <b-textarea
          v-else
          v-model="emailCampaign.content"
          class="background-light-blue-inputs mt-2"
          rows="10"
          size="sm"
          no-resize
          readonly
          maxlength="280"
      >
      </b-textarea>
    </b-col>
  </b-row>
</template>
<script>
  export default {
    props: {
      emailCampaign: {
        type: Object,
        default: () => {}
      },
      selectedCustomers: {
        type: Array,
        default: () => []
      },
      selectedSubscriptions: {
        type: Array,
        default: () => []
      },
      selectedActivities: {
        type: Array,
        default: () => []
      },
      selectedGroups: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      pushContent(){
        return this.isPushCampaign && this.emailCampaign.title !== null ? '.push-content' : '.content';
      },
      isEmailCampaign() {
        return this.$route.name === 'campaign_email_report';
      },
      isPushCampaign() {
        return this.$route.name === 'campaign_push_report';
      }
    },
    components: {
      ConfigIndex: () => import('./config/Index'),
      EmailEditor: () => import('@custom/EmailEditor')
    }
  }
</script>
<style lang="scss" scoped>
.nbr-title {
  font: normal normal 16px Avenir;
  letter-spacing: 0.63px;
  color: #333333;
}

.col-divider {
    -ms-flex: 0 0 57.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 57.333333%;
    max-width: 57.333333%;
}
</style>
